<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.name"
      label="Название"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-select
      v-model="input.powers"
      label="Права"
      :items="POWER.allPower"
      item-text="keyName"
      item-value="id"
      return-object
      multiple
      :rules="[$validate.arrRequired]"
    >
    </v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import role from "@/model/role";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {
        name: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      POWER: "Power/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getPowers();
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      let BodyPowers = await this.getRolePower(this.$route.params.id);
      let BodyName = await this.getRoleInfo(this.$route.params.id);
      body = {
        id: this.$route.params.id,
        name: BodyName.data.name,
        powers: BodyPowers.data.powers,
      };
    }
    let model = new role();
    this.input = model.setInput(this.mode, body);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getRolePower: "RolePower/GET_ROLE_POWERS",
      getRoleInfo: "Role/GET_ROLE",
      addRole: "Role/ADD_ROLE",
      editRole: "RolePower/EDIT_ROLE_POWERS",
      getPowers: "Power/GET_ALL_POWERS",
    }),
    async addButton() {
      this.loading = true;
      let createInput = {
        name: this.input.name,
        powerIds: this.input.powers.map((e) => e.id),
      };
      let response = await this.addRole(createInput);
      if (response.type === "success") {
        this.$router.push("/roles");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: this.getError(response.errors),
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let editInput = {
        roleId: this.$route.params.id,
        powerIds: Object.values(this.input.powers.map((e) => e.id)),
      };
      let response = await this.editRole(editInput);
      if (response.type === "success") {
        this.$router.push("/roles");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    getError(errors) {
      let error = "";
      for (let i = 0; i < errors.length; i++) {
        error += errors[i].defaultMessage + ", ";
      }
      return error;
    },
  },
};
</script>
